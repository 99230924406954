import { graphql } from 'gatsby'
import get from 'lodash/get'
import PropTypes from 'prop-types'
import React, { useMemo } from 'react'
import tw from 'twin.macro'
import Hero from '../../components/hero'
import Layout from '../../components/layout'
import LegalContent from '../../components/legal-content'
import { flatten } from '../../utils/data'

const LegalPage = ({ data: { legal }, location }) => {
  const page = useMemo(() => flatten(legal || {}, ['hero']), [legal])

  return (
    <Layout pageTags={page.seoMetaTags} noIndex={page.noIndex} location={location} >
      <section css={tw`relative bg-smoke-grey`}>
        <Hero
          title={get(page, 'hero.titleNode')}
          titleHeadingType="h1"
          description={get(page, 'hero.descriptionNode')}
          extended
        />
      </section>
      <LegalContent
        menuTitle={get(page, 'sideMenuLabel')}
        title={get(page, 'title')}
        date={get(page, 'date')}
        content={get(page, 'contentNode')}
        style={tw`pt-20 pb-24 lg:py-32`}
      />
    </Layout>
  )
}

LegalPage.propTypes = {
  data: PropTypes.shape({
    legal: PropTypes.object.isRequired,
  }),
  pageContext: PropTypes.shape({
    slug: PropTypes.string,
  }),
  location: PropTypes.object,
}

export default LegalPage

export const query = graphql`
  query LegalQuery($locale: String!) {
    legal: datoCmsBrokerageEuLegal(locale: { eq: $locale }) {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      noIndex
      hero {
        descriptionNode {
          childMarkdownRemark {
            html
          }
        }
        titleNode {
          childMarkdownRemark {
            html
          }
        }
      }
      title
      date
      contentNode {
        childMarkdownRemark {
          html
        }
      }
      sideMenuLabel
    }
  }
`
